import { createStore, useStore } from "vuex";
import { EDeviceTypeName } from "@/global";
import { getRealValue } from "@/utils/dataProcess";
import severalDisplay from './modules/severalDisplay';
import * as api from '@/serve/menu'


const initStateFunc = () => ({
  mapLoading: false,
  currentOrg: null,
  msgTotal: 0,
  wsEvent: {
    mapElementCreat: {},
    mapElementUpdate: {},
    mapElementDelete: {},
  },
  deviceStatusEvent: {
    deviceOnline: {},
    deviceOffline: {},
  },
  deviceState: {
    gatewayInfo: {},
    deviceInfo: {},
    dockInfo: {},
    currentSn: "",
    currentType: -1,
  },
  osdVisible: {
    // osd 显示设备相关信息
    sn: "",
    callsign: "",
    model: "",
    visible: false,
    gateway_sn: "",
    is_dock: false,
    payloads: null,
  },
  waylineInfo: {},
  dockInfo: {},
  heqCh4: {},
  hmsInfo: {},
  // 机场指令执行状态信息
  devicesCmdExecuteInfo: {},
  mqttState: null, // mqtt 实例
  clientId: "", // mqtt 连接 唯一客户端id
  dockVisible: {
    // osd 显示设备相关信息
    sn: "",
    callsign: "",
    model: "",
    visible: false,
    gateway_sn: "",
    is_dock: false,
    gateway_model: "",
    payloads: null,
    in_domain: "",
  },
  devicesWithIcons: {},
  // 直播信息
  agora: {
    // '4TADL2E0010045': {
    //   appId: 'b95fa368c7b64db1b7c15dbd16651211',
    //   userId: 0,
    //   token: '007eJxTYLjYv70p/VyVR8T+ptzI0IUq16WbbDIL17ObLrftyRXcdViBIcnSNC3R2Mwi2TzJzCQlyTDJPNnQNCUpxdDMzNTQyNBQKDUltSGQkSFNSZuZkQECQXw+BpMQRxcfI1cDA0MDAxNTBgYAKyMeuA==',
    // }
  },
  agoraClient: {},
  clickLocation: {
    latitude: null,
    longitude: null,
    height: null,
  },
  onlineDeviceSnList: [],
  equipmentPosition: {},
  messagePosition: {},
  paramsPosition: {},
  terrainProvider: null,
  editList: [],
  viewer: null,
  shouldAnimate: true,
  currentSn: {
    currentDockSn: null,
    currentDeviceSn: null,
  },
  flyToPointParams: {},
  globalSpeed: 10,
  wayLine: [],
  polygon: [],
  planeConfig: {
    speed: 15,
    yaw: 0,
    dem: false,
    overlay: 70,
    height: 170,
    buffer: 0,
    outwardExpansion: 50,
  },
  // 储存航点航线数据
  airlineListInfo: [],
  taskInfo: {}, //任务回传信息
  currentDockSn: null, //当前操作机场的sn
  projectName:'',
  groupId:'',
  globePhoto:[true,true],
  imageFormat: ['visable', 'ir'],
});

const getters = {
  msgTotal:state => {
    return state.msgTotal;
  },
  shouldAnimate: state => {
    return state.shouldAnimate;
  },
  airlineListInfo: state => {
    return state.airlineListInfo;
  },
};
const mutations = {
  set_airlineListInfo(state, data) {
    state.airlineListInfo = data
  },
  set_mapLoading(state){
    state.mapLoading = true
  },
  SET_CURRENT_ORG(state, value) {
    state.currentOrg = value;
  },
  SET_MSRTOTAL(state, value) {
    state.msgTotal = value;
  },
  SET_CURRENT_DOCKSN(state, info) {
    // console.log(info, "info");
    state.currentDockSn = info;
  },
  SET_TASK_INFO(state, info) {
    // console.log(info,'infoinfo')
    const docksn = info.sn;
    const obj = {};
    // 当前飞行信息全部存进去
    obj[docksn] = info;
    state.taskInfo = obj;
  },
  SET_DEVICE_INFO(state, info) {
    // 设置飞行器（设备）相关数据
    state.deviceState.deviceInfo[info.sn] = info.host;
    state.deviceState.currentSn = info.sn;
    state.deviceState.currentType = EDeviceTypeName.Aircraft;
  },
  SET_GATEWAY_INFO(state, info) {
    state.deviceState.gatewayInfo[info.sn] = info.host;
    state.deviceState.currentSn = info.sn;
    state.deviceState.currentType = EDeviceTypeName.Gateway;
  },
  SET_DOCK_INFO(state, info) {
    // 设置机场相关数据
    if (Object.keys(info.host).length === 0) {
      return;
    }
    if (!state.deviceState.dockInfo[info.sn]) {
      state.deviceState.dockInfo[info.sn] = {};
    }
    state.deviceState.currentSn = info.sn;
    state.deviceState.currentType = EDeviceTypeName.Dock;
    const dock = state.deviceState.dockInfo[info.sn];
    if (getRealValue(info.host.mode_code)) {
      const basic_osd = dock.basic_osd || {};
      Object.keys(info.host).forEach((key) => {
        if (getRealValue(info.host[key])) {
          basic_osd[key] = info.host[key];
        }
      });
      dock.basic_osd = basic_osd;
      return;
    }
    if (info.host.wireless_link) {
      const link_osd = dock.link_osd || {};
      Object.keys(info.host).forEach((key) => {
        if (getRealValue(info.host[key])) {
          link_osd[key] = info.host[key];
        }
      });
      dock.link_osd = link_osd;
      return;
    }
    if (getRealValue(info.host.job_number)) {
      const work_osd = dock.work_osd || {};
      Object.keys(info.host).forEach((key) => {
        if (getRealValue(info.host[key])) {
          work_osd[key] = info.host[key];
        }
      });
      dock.work_osd = work_osd;
    }
  },
  SET_HEQ_CH4(state, info) {
    state.heqCh4[info.sn] = info.host
  },
  // 设置在线设备
  SET_DEVICE_ONLINE(state, info) {
    state.deviceStatusEvent.deviceOnline = info;
  },
  // 设置离线设备
  SET_DEVICE_OFFLINE(state, info) {
    state.deviceStatusEvent.deviceOffline = info;
    delete state.deviceState.gatewayInfo[info.sn];
    delete state.deviceState.deviceInfo[info.sn];
    delete state.deviceState.dockInfo[info.sn];
    delete state.hmsInfo[info.sn];
  },
  // 设置在线设备信息，主要是点击显示弹窗
  SET_DEVICES_WITH_ICONS(state, info) {
    state.devicesWithIcons = info
    sessionStorage.setItem("heqDevicesWithIcons", JSON.stringify(info))
  },
  SET_OSD_VISIBLE_INFO(state, info) {
    // 机场 在线设备弹窗数据
    // console.log('%c [ info ]-199', 'font-size:13px; background:pink; color:#bf2c9f;', info)
    // console.trace(info)
    state.dockVisible = info;
  },
  SET_SELECT_WAYLINE_INFO(state, info) {
    state.waylineInfo = info;
  },
  SET_SELECT_DOCK_INFO(state, info) {
    state.dockInfo = info;
  },
  SET_DEVICE_HMS_LIST_INFO(state, info) {
    state.hmsInfo[info.sn] = info.list;
  },
  SET_DEVICE_HMS_INFO(state, info) {
    const hmsList = state.hmsInfo[info.sn];
    state.hmsInfo[info.sn] = info.host.concat(hmsList ?? []);
  },
  SET_DEVICES_CMD_EXECUTE_INFO(state, info) {
    // 保存设备指令ws消息推送
    if (!info.sn) {
      return;
    }
    if (state.devicesCmdExecuteInfo[info.sn]) {
      const index = state.devicesCmdExecuteInfo[info.sn].findIndex(
          (cmdExecuteInfo) => cmdExecuteInfo.biz_code === info.biz_code
      );
      if (index >= 0) {
        // 丢弃前面的消息
        if (
            state.devicesCmdExecuteInfo[info.sn][index].timestamp > info.timestamp
        ) {
          return;
        }
        state.devicesCmdExecuteInfo[info.sn][index] = info;
      } else {
        state.devicesCmdExecuteInfo[info.sn].push(info);
      }
    } else {
      state.devicesCmdExecuteInfo[info.sn] = [info];
    }
  },
  CLEAR_CMD_LIST(state, sn) {
    state.devicesCmdExecuteInfo[sn] = [];
  },
  SET_MQTT_STATE(state, mqttState) {
    state.mqttState = mqttState;
  },
  SET_CLIENT_ID(state, clientId) {
    state.clientId = clientId;
  },
  SET_AGORA_MESSAGE(state, info) {
    state.agora[info.sn] = {
      appId: info.appId,
      token: info.token,
      userId: info.userId,
    };
  },
  SET_AGORA_CLIENT(state, info) {
    state.agoraClient[info.sn] = info.client;
  },
  SET_CLICK_LOCATION(state, info) {
    state.clickLocation.latitude = info.latitude;
    state.clickLocation.longitude = info.longitude;
    state.clickLocation.height = info.height;
  },
  SET_ONLINE_DEVICE_SN_LIST_BY_PROJECT(state, info) {
    state.onlineDeviceSnList = info;
  },
  SET_EQUIPMENT_POSITION(state, info) {
    state.equipmentPosition[info.sn] = {
      ...state.equipmentPosition[info.sn],
      ...info.position,
    };
    if (info.zIndex) {
      const zIndexList = [];
      const equKeys = Object.keys(state.equipmentPosition);
      equKeys.forEach((item) => {
        if (state.equipmentPosition[item].zIndex)
          zIndexList.push(state.equipmentPosition[item].zIndex);
      });
      const msgKeys = Object.keys(state.messagePosition);
      msgKeys.forEach((item) => {
        if (state.messagePosition[item].zIndex)
          zIndexList.push(state.messagePosition[item].zIndex);
      });
      const prmKeys = Object.keys(state.paramsPosition);
      prmKeys.forEach((item) => {
        if (state.paramsPosition[item].zIndex)
          zIndexList.push(state.paramsPosition[item].zIndex);
      });
      state.equipmentPosition[info.sn].zIndex =
          zIndexList.length > 0 ? Math.max(...zIndexList) + 1 : 99;
    }
  },
  SET_MESSAGE_POSITION(state, info) {
    state.messagePosition[info.sn] = {
      ...state.messagePosition[info.sn],
      ...info.position,
    };
    if (info.zIndex) {
      const zIndexList = [];
      const equKeys = Object.keys(state.equipmentPosition);
      equKeys.forEach((item) => {
        if (state.equipmentPosition[item].zIndex)
          zIndexList.push(state.equipmentPosition[item].zIndex);
      });
      const msgKeys = Object.keys(state.messagePosition);
      msgKeys.forEach((item) => {
        if (state.messagePosition[item].zIndex)
          zIndexList.push(state.messagePosition[item].zIndex);
      });
      const prmKeys = Object.keys(state.paramsPosition);
      prmKeys.forEach((item) => {
        if (state.paramsPosition[item].zIndex)
          zIndexList.push(state.paramsPosition[item].zIndex);
      });
      state.messagePosition[info.sn].zIndex =
          zIndexList.length > 0 ? Math.max(...zIndexList) + 1 : 99;
    }
  },
  SET_PARAMS_POSITION(state, info) {
    state.paramsPosition[info.sn] = {
      ...state.paramsPosition[info.sn],
      ...info.position,
    };
    if (info.zIndex) {
      const zIndexList = [];
      const equKeys = Object.keys(state.equipmentPosition);
      equKeys.forEach((item) => {
        if (state.equipmentPosition[item].zIndex)
          zIndexList.push(state.equipmentPosition[item].zIndex);
      });
      const msgKeys = Object.keys(state.messagePosition);
      msgKeys.forEach((item) => {
        if (state.messagePosition[item].zIndex)
          zIndexList.push(state.messagePosition[item].zIndex);
      });
      const prmKeys = Object.keys(state.paramsPosition);
      prmKeys.forEach((item) => {
        if (state.paramsPosition[item].zIndex)
          zIndexList.push(state.paramsPosition[item].zIndex);
      });
      state.paramsPosition[info.sn].zIndex =
          zIndexList.length > 0 ? Math.max(...zIndexList) + 1 : 99;
    }
  },
  SET_TERRAIN_PROVIDER(state, info) {
    state.terrainProvider = info;
  },
  SET_EDITLIST(state, data) {
    state.editList = data;
  },
  SET_VIEWER(state, info) {
    state.viewer = info;
  },
  SET_CURRENT_SN(state, info) {
    state.currentSn = {
      ...state.currentSn,
      ...info,
    };
  },
  SET_FLY_TO_POINT(state, info) {
    state.flyToPointParams[info.sn] = info.body;
  },
  SET_PLANE_LINE(state, info) {
    state.wayLine = info;
  },
  SET_POLYGON(state, info) {
    state.polygon = info;
  },
  SET_PLANE_CONFIG(state, info) {
    state.planeConfig[info.key] = info.value;
  },
  CLAER_CONFIG(state) {
    state.planeConfig = {
      speed: 15,
      yaw: 0,
      dem: false,
      overlay: 70,
      height: 170,
      buffer: 0,
      outwardExpansion: 50,
    };
  },
  SET_PROJECT_NAME(state,info) {
    state.projectName = info
  },
  SET_GROUP_ID(state,info){
    state.groupId = info
  },
  SET_GLOBE_PHOTO(state,info){
    state.globePhoto[info] = !state.globePhoto[info]
  },
  SET_GLOBE_PHOTO_ARR(state,info){
    state.globePhoto = info
  },
  SET_IMAGE_FORMAT(state,info){
    state.imageFormat = info
  },
  SET_GLOBAL_SPEED(state,info){
    state.globalSpeed = info
  },
  set_should_Animate(state,info){
    state.shouldAnimate = info
  },
  // 不修改数据 -- start
  SET_MAP_ELEMENT_CREATE() {},
  SET_MAP_ELEMENT_UPDATE() {},
  SET_MAP_ELEMENT_DELETE() {}
  // 不修改数据 -- end
};

const actions = {
  async ASYNC_SET_MSRTOTAL (state) {
    const res = await api.extendNoticeAnyoneMyNoticeCount({})
    if (res) {
        let num
        // console.log(res.data, '我的未读消息未读消息')
        num = Number(res.data.createAccountNum) + Number(res.data.createTenantNum) + Number(res.data.joinProjectNum) + Number(res.data.joinTenantNum)
        state.commit('SET_MSRTOTAL',num)
    }
  }
};

const storeOptions = {
  state: initStateFunc,
  getters,
  mutations,
  actions,
  modules: {
    severalDisplay,
  }
};

const rootStore = createStore(storeOptions);

export default rootStore;

export const storeKey = Symbol("");

export function useMyStore() {
  return useStore(storeKey);
}
